body {
  padding: 0px !important;
  margin: 0px !important;
  background-color: #f7f5f5 !important;
}

.logo-image {
  width: 25px !important;
  height: 25px !important;
}

.new-border-radius {
  border-radius: 5px !important;
}

.c-black {
  color: #1f1f1f;
}
.c-white {
  color: white;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.sub-header-design {
  padding: 15px !important;
}

.main-header-design {
  /* padding: 15px !important; */
  background-color: #163c5b !important;
  box-shadow: 0 0 30px 0 rgb(0 0 0 / 10%);
  position: sticky !important;
  top: 0px !important;
}

.company-logo {
  width: 70% !important;
  padding-top: 10px;
}

.new-text-left {
  text-align: start !important;
}

.new-padding-top {
  padding-top: 22px !important;
  padding-bottom: 22px !important;
}

.nav-bar-display {
  display: inline !important;
  padding: 22px 15px 22px 15px !important;
}

.new-text-decoration {
  text-decoration: none !important;
}

.fw-weight {
  font-weight: 600 !important;
}

.new-image-width {
  width: 100% !important;
}

.mobile-app-development {
  font-size: 4rem !important;
  line-height: 1.2 !important;
  font-weight: 550 !important;
  margin-bottom: 10px !important;
  color: #6e31a4;
}

.mobile-app-development999 {
  font-size: 2.7rem !important;
  line-height: 1.2 !important;
  font-weight: 550 !important;
  margin-bottom: 10px !important;
}

.mobile-app-development11 {
  font-size: 4rem !important;
  line-height: 1.2 !important;
  font-weight: 550 !important;
  margin-bottom: 10px !important;
  color: #6a50ff;
}

.mobile-app-development12 {
  font-size: 4rem !important;
  line-height: 1.2 !important;
  font-weight: 550 !important;
  margin-bottom: 10px !important;
  color: #1d0c43;
}

.mobile-app-development13 {
  font-size: 4rem !important;
  line-height: 1.2 !important;
  font-weight: 550 !important;
  margin-bottom: 10px !important;
  color: #e6b52d;
}

.mobile-app-development14 {
  font-size: 4rem !important;
  line-height: 1.2 !important;
  font-weight: 550 !important;
  margin-bottom: 10px !important;
  color: #ff40b1;
}

.mobile-app-development1 {
  font-weight: 500 !important;
  color: #1f1f1f !important;
}

.slider-text-margin {
  margin-top: 7rem !important;
  text-align: start !important;
}

.mobile-app-text {
  font-size: 17px !important;
  line-height: 1.8 !important;
  margin-top: 20px !important;
}

.get-a-quote-button {
  margin-top: 27px;
  background: #2ab299;
  color: white;
  border: none;
  padding: 15px 50px 15px 50px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 5px;
}

/* .get-a-quote-button1{
    position: absolute;
    color: #2ab299;
    font-size: 30px;
    top: 33.5rem;
    left: 17.6rem;
    transition: all .2s;
} */
/* .get-a-quote-button:hover .get-a-quote-button1{
    left: 18.6rem;
} */
.slider-control-centerleft {
  display: none !important;
}

.slider-control-centerright {
  display: none !important;
}

.vertical-line-design {
  border-left: 3px solid #2ab299;
  height: 525px;
  margin-top: 20px !important;
}

.active-button-slider {
  background: #2ab299;
  position: absolute;
  font-size: 13px !important;
  font-weight: 600 !important;
  color: white;
  padding: 3px 10px 3px 9px;
  border-radius: 50%;
  cursor: pointer !important;
}

.active-button-slider1 {
  background: #2ab299;
  position: absolute;
  font-size: 7px !important;
  font-weight: 600 !important;
  color: white;
  padding: 3px 6px 3px 6px;
  border-radius: 50%;
  cursor: pointer !important;
}

.active-top-left {
  top: 12rem;
}

.active-top-left1 {
  top: 17rem;
}

.active-top-left2 {
  top: 22rem;
}

.active-top-left3 {
  top: 27rem;
}

.active-top-left4 {
  top: 32rem;
}

.active-top-left5 {
  top: 37rem;
}

.active-left {
  left: 5.3rem;
}

.active-left1 {
  left: 5.59rem;
}

.my-left-margin {
  margin-left: 6rem !important;
}

.fs-2 {
  font-size: 2rem !important;
  font-family: 'Poppins-Bold';
}

.new-body-text {
  text-align: start !important;
  margin-top: 6rem !important;
}

.fw-bolder {
  font-weight: 600 !important;
}

.my-client-margin {
  margin-top: 5.5rem !important;
}

.my-client-margin12 {
  margin-top: 2rem !important;
}

.card-design-services {
  min-height: 320px;
  overflow: hidden;
  position: relative;
  padding: 45px 25px;
  border-radius: 15px;
  box-shadow: 0 10px 15px 0 rgb(0 0 0 / 10%);
}

.card-design-services1 {
  min-height: 240px;
  overflow: hidden;
  position: relative;
  padding: 45px 25px;
  border-radius: 15px;
  box-shadow: 0 10px 15px 0 rgb(0 0 0 / 10%);
}

.card-design-services-text {
  font-family: 'Poppins-Bold';
  font-size: 23px !important;
  line-height: 35px !important;
  text-align: start !important;
  font-weight: 600 !important;
}

.card-design-services-another-text {
  color: #252b33;
  font-family: 'Poppins-Regular' !important;
  font-size: 17px !important;
  cursor: pointer;
  text-align: start !important;
  margin-top: 5px !important;
}

.card-design-services-another-text2 {
  color: #252b33;
  font-family: 'Poppins-Regular' !important;
  font-size: 18px !important;
  cursor: pointer;
  text-align: start !important;
  margin-top: 5px !important;
}

.card-design-services-another-text1 {
  color: #252b33;
  font-family: 'Poppins-Regular' !important;
  font-size: 17px !important;
  cursor: pointer;
  margin-top: 20px !important;
}

.steps-card-texts {
  color: #252b33;
  font-family: 'Poppins-Regular' !important;
  font-size: 16px !important;
  cursor: pointer;
  margin-top: 15px !important;
}

.card-images-design {
  width: 5%;
  position: relative;
  top: 2px;
  margin-right: 8px;
}

.fs-1 {
  font-size: 1rem !important;
  font-family: 'Poppins-Bold';
  font-weight: 600 !important;
}

.steps-text-margin {
  margin-top: 1.3rem !important;
}

.all-margin {
  margin-top: 1rem !important;
}

.card-design-services2 {
  min-height: 221px;
  overflow: hidden;
  position: relative;
  padding: 45px 25px;
  border-radius: 15px;
  box-shadow: 0 10px 15px 0 rgb(0 0 0 / 10%);
}

.card-design-services3 {
  min-height: 140px;
  overflow: hidden;
  position: relative;
  padding: 40px 20px;
  border-radius: 15px;
  box-shadow: 0 10px 15px 0 rgb(0 0 0 / 10%);
}

.card-design-services3:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -0px;
  right: -0px;
  background: #2ab299;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.25s ease-out;
}

.card-design-services3:hover:before {
  transform: scale(21);
}

.card-design-services3:hover p {
  transition: all 0.3s ease-out;
  color: rgba(255, 255, 255, 0.8);
}

.card-design-services3:hover h3 {
  transition: all 0.3s ease-out;
  color: #ffffff;
}

.footer-background {
  background-color: #163c5b !important;
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
  margin-top: 3rem !important;
}

.footer-text {
  margin-bottom: 20px;
  color: #2ab299;
  font-size: 14px;
  font-weight: 700;
  text-align: start;
  /* margin-bottom: 15px !important; */
}

.footer-link {
  display: block;
  margin-bottom: 15px;
  text-align: start !important;
  color: #d4d6e4 !important;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 7px !important;
  cursor: pointer !important;
}

.new-footer-padding {
  padding-bottom: 2rem !important;
}

.my-down-footer {
  border-top: 1px solid #2ab299;
}

.new-width {
  width: 80% !important;
}

.my-border-bottom2 {
  border-bottom: 3px solid #2ab299;
  width: 12% !important;
  margin-top: 13px !important;
  margin-bottom: 30px !important;
  line-height: 1.5em !important;
}

.copy-right-fonts {
  font-size: 12px !important;
  text-align: start !important;
  color: white !important;
}

.copy-right-fonts1 {
  color: #2ab299 !important;
}

.my-down-footer {
  border-top: 1px solid #2ab299;
  padding-top: 2rem !important;
}

.new-drop-down-bar {
  position: relative !important;
}

.drop-down-link-tag {
  display: inherit;
  padding: 9px 6px 9px 6px;
  border-radius: 5px;
  margin-bottom: 4px;
}

.drop-down-link-tag:hover {
  background-color: #f0efef;
}

.new-drop-down-bar:hover .drop-down-box {
  visibility: visible !important;
  opacity: 1 !important;
  transform: translateY(0px);
}

.new-drop-down-bar .drop-down-box {
  position: absolute !important;
  background-color: white !important;
  padding: 10px !important;
  width: 16rem !important;
  box-shadow: 0 10px 15px 0 rgb(0 0 0 / 10%) !important;
  left: 0px !important;
  border-radius: 5px !important;
  top: 3.9rem !important;
  visibility: hidden !important;
  transform: translateY(50px);
  opacity: 0 !important;
  /* z-index: 9999999999999999 !important; */
  transition: 0.4s all;
}

.web-design-css {
  line-height: 30px !important;
  font-size: 16px !important;
  text-align: justify !important;
  margin-bottom: 20px !important;
}

.web-design-css1 {
  line-height: 25px !important;
  font-size: 13px !important;
  text-align: center !important;
  margin-top: 10px !important;
  font-weight: 600 !important;
}

.web-design-services-card {
  /* min-height: 221px; */
  overflow: hidden;
  position: relative;
  padding: 40px 20px;
  border-radius: 15px;
  box-shadow: 0 10px 15px 0 rgb(0 0 0 / 10%);
}

.web-design-services-card1 {
  min-height: 110px;
  overflow: hidden;
  position: relative;
  padding: 40px 20px;
  border-radius: 15px;
  box-shadow: 0 10px 15px 0 rgb(0 0 0 / 10%);
}

.our-team-images {
  background-image: url('../image/office.jpg');
  height: 600px !important;
  width: 100% !important;
  background-size: cover !important;
}

.our-team-background-color {
  background-color: #18181891 !important;
  height: 600px;
}

.our-team-text {
  font-size: 4rem !important;
  line-height: 1.2 !important;
  font-weight: 550 !important;
  margin-bottom: 10px !important;
  color: white;
}

.our-team-small-text {
  font-size: 17px !important;
  line-height: 1.8 !important;
  margin-top: 20px !important;
  color: white !important;
}

.our-team-text-margin {
  margin-top: 10rem !important;
  text-align: start !important;
}

.our-team-height {
  height: 420px !important;
}

.clear-fix:after {
  display: block;
  clear: both;
  content: "";
}

.img-responsive {
  width: 100%;
  height: 450px;
  border-radius: 15px !important;
  box-shadow: 0 10px 15px 0 rgb(0 0 0 / 10%);
}

.card__collection {
  position: relative;
  display: block;
  font-family: "Do Hyeon", sans-serif;
  /* overflow: hidden; */
}

.card__collection .cards {
  width: 100%;
  height: 450px;
  box-shadow: 0 10px 15px 0 rgb(0 0 0 / 10%);
  display: block;
  background-size: cover;
  float: left;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  background-position: center;
  margin: 10px;
}

.card__collection .cards--one:hover:after {
  bottom: -20px;
}

.card__collection .cards--one:hover:before {
  bottom: -10px;
}

.card__collection .cards--one:hover .cards--one__rect {
  left: 45%;
}

.card__collection .cards--one:hover .cards--one__rect--back {
  left: 50%;
}

.card__collection .cards--one::after {
  content: "";
  display: block;
  position: absolute;
  height: 70px;
  transform: rotate(-3deg);
  background: #e660e3;
  position: absolute;
  bottom: -80px;
  left: 0;
  right: -10px;
  z-index: 9;
  transition: all 0.2s ease-in;
  transition-delay: 0.3s;
}

.card__collection .cards--one:before {
  content: "";
  display: block;
  position: absolute;
  height: 80px;
  transform: rotate(-3deg);
  bottom: -90px;
  left: 0;
  background: #fff;
  right: -10px;
  z-index: 5;
  transition: all 0.2s ease-in;
  transition-delay: 0.1s;
}

.card__collection .cards--one__rect {
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  background: #0f9bc0;
  width: 126px;
  height: 55px;
  transform: skewY(5deg);
  position: absolute;
  display: block;
  top: 60%;
  left: -45%;
  z-index: 1;
  line-height: 3.3rem;
  text-align: center;
  transition: all 0.2s ease-in;
}

.card__collection .cards--one__rect--back {
  display: block;
  background: rgba(34, 65, 154, 0.8);
  width: 126px;
  height: 55px;
  transform: skewY(7deg);
  position: absolute;
  top: 65%;
  left: -50%;
  transition: all 0.2s ease-in;
  transition-delay: 0.3s;
}

.card__collection .cards--one__rect p {
  transform: skewY(-7deg);
  position: relative;
}

.card__collection .cards--two {
  position: relative;
  backface-visibility: hidden;
}

.card__collection .cards--two .card-text {
  position: absolute;
  top: 79.4%;
  left: -100%;
  text-transform: capitalize;
  color: #fff;
  font-size: 16px !important;
  z-index: 8;
  transition: all 0.6s ease;
  font-weight: 600 !important;
}

.card__collection .cards--two .card-text1 {
  position: absolute;
  top: 86.5%;
  left: -100%;
  text-transform: capitalize;
  color: #fff;
  font-size: 13px !important;
  z-index: 8;
  transition: all 0.6s ease;
  font-weight: 600 !important;
}

.card__collection .cards--two:hover p {
  left: 2%;
}

.card__collection .cards--two:hover img {
  transform: translateY(0px);
}

.card__collection .cards--two:hover .cards--two__rect {
  top: 75%;
}

.card__collection .cards--two:hover .cards--two__rect:before {
  transform: translateY(15px);
}

.card__collection .cards--two:hover li {
  transform: translateY(0);
}

.card__collection .cards--two:hover .cards--two__tri {
  right: -40%;
}

.card__collection .cards--two:hover .cards--two__tri:before {
  right: -312px;
}

.card__collection .cards--two img {
  transition: all 0.2s ease;
}

.card__collection .cards--two__tri {
  border-top: 220px solid transparent;
  border-bottom: 190px solid transparent;
  border-right: 288px solid #fff;
  opacity: 0.9;
  position: absolute;
  display: block;
  top: 0;
  right: -100%;
  transition: all 0.3s ease-in-out;
}

.card__collection .cards--two__tri:before {
  border-top: 220px solid transparent;
  border-bottom: 190px solid transparent;
  border-right: 288px solid #163c5b;
  position: absolute;
  content: "";
  display: block;
  top: -220px;
  right: -612px;
  transition: all 0.3s ease-in-out;
  transition-delay: 0.2s;
}

.card__collection .cards--two__rect {
  width: 750px;
  height: 400px;
  background: #fff;
  display: block;
  position: absolute;
  top: 175%;
  left: -78%;
  transform: rotate(30deg);
  z-index: 5;
  opacity: 0.9;
  transition: all 0.3s ease-in-out;
}

.card__collection .cards--two__rect:before {
  content: "";
  display: block;
  width: 100%;
  position: relative;
  height: 100%;
  background: #2ab299;
  transform: translateY(200px);
  z-index: 2;
  transition: all 0.3s ease-in-out;
  transition-delay: 0.1s;
}

.card__collection .cards--two ul {
  list-style: none;
  position: absolute;
  bottom: -13px;
  left: -38px;
  z-index: 9;
}

.card__collection .cards--two ul li {
  display: inline-block;
  font-size: 16px;
  margin: 7px;
  color: #fff;
  transition: all 0.2s ease-in-out;
  transform: translateY(100px);
}

.card__collection .cards--two ul li:nth-child(2) {
  transition-delay: 0.2s;
}

.card__collection .cards--two ul li:nth-child(3) {
  transition-delay: 0.3s;
}

.card__collection .cards--two ul li:nth-child(4) {
  transition-delay: 0.4s;
}

.signage-app-development {
  font-size: 4rem !important;
  line-height: 1.2 !important;
  font-weight: 550 !important;
  margin-bottom: 10px !important;
  color: #f49421;
}

.card-portfolio {
  /* position: absolute; */
  /* top: 50%;
  left: 50%; */
  transform: translate(0%, 0%);
  width: 100%;
  height: 300px;
  background: #000;
}

.card-portfolio .image {
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.card-portfolio .image img {
  width: 100%;
  transition: .5s;
  height: 300px;
}

.card-portfolio:hover .image img {
  opacity: .5;
  transform: translateX(30%);
}

.card-portfolio .details {
  position: absolute;
  top: 0;
  left: 0;
  width: 70%;
  height: 100%;
  background: #2ab299;
  transition: .5s;
  transform-origin: left;
  transform: perspective(2000px) rotateY(-90deg);
}

.card-portfolio:hover .details {
  transform: perspective(2000px) rotateY(0deg);
}

.card-portfolio .details .center {
  padding: 20px;
  text-align: center;
  background: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.card-portfolio .details .center h1 {
  margin: 0;
  padding: 0;
  color: #163c5b;
  /* line-height: 20px; */
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 17px;
}

.card-portfolio .details .center h1 span {
  font-size: 14px;
  color: #262626;
}

.card-portfolio .details .center p {
  margin: 10px 0;
  padding: 0;
  color: #262626;
}

.card-portfolio .details .center ul {
  margin: 10px auto 0;
  padding: 0;
  display: table;
}

.card-portfolio .details .center ul li {
  list-style: none;
  margin: 0 5px;
  float: left;
}

.card-portfolio .details .center ul li a {
  display: block;
  background: #262626;
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  transform: .5s;
  border-radius: 5px !important;
}

.card-portfolio .details .center ul li a:hover {
  background: #163c5b;
}

.mobile-app-development121 {
  font-size: 1.6rem !important;
  line-height: 1.2 !important;
  font-weight: 550 !important;
  margin-bottom: 10px !important;
  color: #1d0c43;
}

.career-form-control {
  width: 100%;
  margin-top: 13px;
  font-size: 11px !important;
  font-weight: 600;
  padding: 5px;
  height: 32px;
  border: 1px solid #d0cdcd;
  border-radius: 5px;
}

.career-form-control1 {
  width: 100%;
  margin-top: 13px;
  font-size: 11px !important;
  font-weight: 600;
  padding: 5px;
  border: 1px solid #d0cdcd;
  border-radius: 5px;
  resize: none;
}

.mobile-app-development1212 {
  font-size: 18px !important;
  font-family: 'Poppins-Medium';
  line-height: 25px;
  font-weight: 550 !important;
  margin-top: 8px !important;
  color: #1d0c43;
  text-align: start !important;
  padding: 10px !important;
}

.blog-detail-font {
  font-size: 33px !important;
  font-weight: 600 !important;
  text-align: start !important;
}

button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}

button.learn-more {
  width: 12rem;
  height: auto;
}

button.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #2ab299;
  border-radius: 1.625rem;
}

button.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}

button.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

button.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
}

button.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #2ab299;
  font-weight: 700 !important;
  font-size: 13px !important;
  padding-top: 14px;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}

button:hover .circle {
  width: 100%;
}

button:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(1rem, 0);
}

button:hover .button-text {
  color: #fff;
}

.services-height-for-read-more {
  height: 155px;
}

.services-height-for-read-more1 {
  height: 122px;
}

.slider-new-margin-left {
  margin-left: 15px !important;
}

.aos-init[data-aos][data-aos].aos-animate {
  transform: unset;
}

.body-fonts {
  font-family: "Poppins-Regular" !important;
  font-variant: normal !important;
}

.header-fonts {
  font-family: 'Poppins-Bold' !important;
  font-variant: normal !important;
}

.blog-fonts {
  font-family: 'Playfair Display', serif !important;
}