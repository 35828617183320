@media (max-width: 575.98px) {
    .res-display{
        display: none !important;
    }
    .res-display-block{
        display: block !important;
    }
    .res-header{
        background: white !important;
        padding: 15px !important;
        box-shadow: 0 10px 15px 0 rgb(0 0 0 / 10%) !important;
    }
    .company-logo {
        width: 11% !important;
        position: absolute;
        left: 15px !important;
        top: 8px !important;
    }
    .fam-24{
        font-size: 24px !important;
    }
    .res-header-background-bottom{
        background-color: white !important;
        width: 24rem !important;
        position: absolute !important;
        /* padding: 20px !important; */
        box-shadow: 0 10px 15px 0 rgb(0 0 0 / 10%) !important;
        text-align: start !important;
        height: 400px !important;
        overflow-x: scroll !important;
        z-index: 2 !important;
    }
    .res-body-border{
        padding-top: 15px !important;
    padding-bottom: 15px !important;
    border-bottom: 2px solid #dfc04b !important;
    }
    .react-multi-carousel-list {
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;
        z-index: -1;
    }
    .new-body-text {
        text-align: start !important;
        margin-top: 3rem !important;
    }
    .my-client-margin {
        margin-top: 3rem !important;
    }
    .res-margin-top{
        margin-top: 15px !important;
    }
    .res-text-align{
        text-align: left !important;
    }
    .slider-text-margin {
        margin-top: 3rem !important;
        text-align: start !important;
    }
    .new-width {
        width: 40% !important;
        margin-bottom: 30px;
    }
}
@media (min-width: 576px) and (max-width: 767.98px) {
    .res-display{
        display: none !important;
    }
    .res-display-block{
        display: block !important;
    }
    .res-header{
        background: white !important;
        padding: 15px !important;
        box-shadow: 0 10px 15px 0 rgb(0 0 0 / 10%) !important;
    }
    .company-logo {
        width: 7% !important;
        position: absolute;
        left: 15px !important;
        top: 8px !important;
    }
    .fam-24{
        font-size: 24px !important;
    }
    .res-header-background-bottom{
        background-color: white !important;
    width: 28rem !important;
    position: absolute !important;
    /* padding: 20px !important; */
    box-shadow: 0 10px 15px 0 rgb(0 0 0 / 10%) !important;
    text-align: start !important;
    height: 400px !important;
    overflow-x: scroll !important;
    z-index: 2 !important;
    left: 0;
    right: 0;
    top: 11rem;
    bottom: 0;
    margin: auto;
    }
    .res-body-border{
        padding-top: 15px !important;
    padding-bottom: 15px !important;
    border-bottom: 2px solid #dfc04b !important;
    }
    .react-multi-carousel-list {
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;
        z-index: -1;
    }
    .new-body-text {
        text-align: start !important;
        margin-top: 3rem !important;
    }
    .my-client-margin {
        margin-top: 3rem !important;
    }
    .res-margin-top{
        margin-top: 15px !important;
    }
    .res-text-align{
        text-align: left !important;
    }
    .slider-text-margin {
        margin-top: 3rem !important;
        text-align: start !important;
    }
    .new-width {
        width: 20% !important;
        margin-bottom: 30px;
    }
}
@media (min-width: 414px) and (max-width: 896px){
    .res-display{
        display: none !important;
    }
    .res-display-block{
        display: block !important;
    }
    .res-header{
        background: white !important;
        padding: 15px !important;
        box-shadow: 0 10px 15px 0 rgb(0 0 0 / 10%) !important;
    }
    .company-logo {
        width: 7% !important;
        position: absolute;
        left: 15px !important;
        top: 8px !important;
    }
    .fam-24{
        font-size: 24px !important;
    }
    .res-header-background-bottom{
        background-color: white !important;
    width: 28rem !important;
    position: absolute !important;
    /* padding: 20px !important; */
    box-shadow: 0 10px 15px 0 rgb(0 0 0 / 10%) !important;
    text-align: start !important;
    height: 400px !important;
    overflow-x: scroll !important;
    z-index: 2 !important;
    left: 0;
    right: 0;
    top: 11rem;
    bottom: 0;
    margin: auto;
    }
    .res-body-border{
        padding-top: 15px !important;
    padding-bottom: 15px !important;
    border-bottom: 2px solid #dfc04b !important;
    }
    .react-multi-carousel-list {
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;
        z-index: -1;
    }
    .new-body-text {
        text-align: start !important;
        margin-top: 3rem !important;
    }
    .my-client-margin {
        margin-top: 3rem !important;
    }
    .res-margin-top{
        margin-top: 15px !important;
    }
    .res-text-align{
        text-align: left !important;
    }
    .slider-text-margin {
        margin-top: 3rem !important;
        text-align: start !important;
    }
    .new-width {
        width: 20% !important;
        margin-bottom: 30px;
    }
}